import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { Pop2 } from './pop2'

const Outer = styled.div`
  background-color: #2490c9;
  color: white;
  padding: min(1.5rem, calc(1.5 / 32 * 100vw)) 0;
  text-align: center;
  font-size: min(0.85rem, calc(0.85 / 32 * 100vw));
  padding-bottom: min(10rem, calc(10 / 32 * 100vw));
`

const Copyright = styled.div`
  margin-top: min(0.25rem, calc(0.25 / 32 * 100vw));
`

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: min(1rem, calc(1 / 32 * 100vw));
`

const Link = styled.div`
  display: block;
  padding: min(0.25rem, calc(0.25 / 32 * 100vw)) 0;
  text-decoration: underline;
  cursor: pointer;
`

const Separetor = styled.div`
  padding: min(0.25rem, calc(0.25 / 32 * 100vw)) min(0.25rem, calc(0.25 / 32 * 100vw));
`

const Title = styled.div`
  font-size: min(1.25rem, calc(1.25 / 32 * 100vw));
  line-height: 1.5;
  color: #000;
  flex: 0 0 auto;
`

const Text = styled.div`
  margin-top: min(3rem, calc(3 / 32 * 100vw));
  font-size: min(0.8rem, calc(0.8 / 32 * 100vw));
  line-height: 2;
  text-align: left;
  color: #000;
  white-space: pre-wrap;
  flex: 1 1 0;
  overflow-y: scroll;
  b {
    font-weight: bold;
  }
`

type FooterProps = {

}
export const Footer: FunctionComponent<FooterProps> = () => {
  const [privacyIsOpen, setPrivacyIsOpen] = useState(false)
  const [tokuteiIsOpen, setTokuteiIsOpen] = useState(false)
  return <Outer>
    <LinkContainer>
      <Link onClick={() => setPrivacyIsOpen(true)}>プライバシーポリシー</Link>{/* FIXME */}
      <Separetor>/</Separetor>
      <Link onClick={() => setTokuteiIsOpen(true)}>特定商取引法に基づく表記</Link>{/* FIXME */}
    </LinkContainer>
    <Copyright>
      © 2022 Good Life Partners. All rights reserved.
    </Copyright>
    {
      privacyIsOpen &&
      <Pop2 onClose={() => setPrivacyIsOpen(false)}>
        <Title>個人情報保護指針</Title>
        <Text>
          株式会社グッドライフパートナーズ(以下、「当社」といいます。)は、当社の提供するサービス(以下、「当社サービス」といいます。)をご利用いただくお客様の個人情報を保護するため、個人情報保護に関する法律（以下、「個人情報保護法」といいます。）その他の関連法令・ガイドラインを遵守するとともに、個人情報を以下のとおり取り扱うこととします。<br />
          <br /><br />
          <b>第1条(個人情報の定義)</b><br />
          本ポリシーにおいて、個人情報とは、個人情報保護法第２条第１項により定義された個人情報、すなわち、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの(他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。)及び個人識別符号が含まれるものを意味するものとします。<br /><br />
          <b>第2条(当社サービスでの個人情報の利用目的)</b><br />
          当社は、当社サービスの利用を通じて取得したお客さまの個人情報を、お客様ご本人の同意なく利用目的の範囲を超えて利用することはありません。当社が取得した個人情報は、下記の目的の範囲内で適正に取り扱いさせていただきます。<br />
          (1)ご本人様確認<br />
          (2)当社サービスの提供<br />
          (3)ご利用料金、遅延損害金等の請求<br />
          (4)サービスの利用に伴うお客様への各種連絡、各種書類又はデータの送付<br />
          ⑸代金決済等における、お客様の金融機関の口座番号やクレジットカードの有効性の確認<br />
          (6)売上の管理<br />
          (7)お客様の年齢、職業、性別、趣味等に合わせた当社サービス上の情報、広告配信のカスタマイズ<br />
          (8)当社サービスの品質向上、改善、利便性の向上<br />
          (9)当社サービスの停止・中止・契約解除の通知<br />
          (10)当社サービスの規定又は当社との契約に違反する行為に対する対応及び当該違反行為の防止<br />
          (11)当社サービスに関する当社の規定、ポリシー等の変更などの通知<br />
          (12)紛争、訴訟などへの対応<br />
          (13)当社サービスに関するご案内、お問い合わせ等への対応<br />
          (14)当社サービスの運用に伴う一時的なバックアップ保管<br />
          (15)当社の提携する専門家に対する信託契約及び遺言の文案の作成の依頼<br />
          (16)当社の提携する専門家に対する信託関連の登記（受託者法人の設立登記を含む。）の依頼<br />
          (17)お客様の信託口座開設、お客様の信託内借入及びこれらに関連して必要となる金融機関との調整<br />
          (18) 信託契約又は遺言に関する公正証書作成のための公証人との調整<br />
          (19) お客様の要望するライフプラン設計及び資産の維持活用の相談への対応<br />
          (20)その他前各号に付帯又は関連する目的<br /><br />
          <b>第3条(個人情報の取得)</b><br />
          当社は、偽りその他不正の手段によらず、適正に個人情報を取得します。<br />
          2 当社は、お客様による当社サービスの利用を通じた取得以外の方法で個人情報を取得する場合には、当社が別途定める方法により、その利用目的を事前に通知又は公表します。<br /><br />
          <b>第4条(利用目的の変更)</b><br />
          当社は、前条の利用目的を、変更前の利用目的と関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には、当社が別途定める方法により、お客様に通知又は公表します。<br /><br />
          <b>第5条(安全管理)</b><br />
          １．当社は、個人データ取扱責任者を定め、個人データ取扱責任者は、個人データの取扱状況の確認、個人データの漏洩等が発生した場合の対応及び安全管理体制の定期的見直しを行います。従業者は、指定された範囲で個人データを取扱い、個人データの不適切な取扱いを発見した場合は個人データ取扱責任者に報告するものとします。<br />
          ２．当社は、個人情報の取扱に関するマニュアルを作成し、従業員の秘密保持義務を明確にし、従業員に対し個人データの取扱について定期的に研修を実施します。<br />
          ３．当社は、個人データを取り扱う従業者の執務エリアの入退室管理、個人データが記録されたパソコン、UBSメモリ及び個人データが記載された書類の施錠できるキャビネット等での保管、個人データが記録されたパソコン及びUBSメモリのパスワード設定、個人データが記録されたパソコン、UBSメモリ及び個人データが記載された書類が廃棄される場合の廃棄確認を行います。<br />
          ４．当社は、個人データを取り扱うパソコン及び使用者の指定、当該パソコンへのパスワード設定によるアクセス制限、OSの最新化、セキュリティ対策ソフトの利用、個人データを含むファイルをメールで送る場合のパスワード設定を行っています。<br />
          5 当社は、個人データの取扱いの全部又は一部を第三者に委託する場合は、当該第三者との間で本ポリシーに準じる内容の秘密保持契約等をあらかじめ締結するとともに、当該第三者において個人データの安全管理が図られるよう、必要かつ適切な監督を行います。<br /><br />
          <b>第6条(外部サービス・リンク)</b><br />
          当社は、LINE、Facebook等の外部サービス(以下、「外部サービス」といいます。)と連携してサービスを提供する場合があります。お客様がその希望により外部サービスと連携した当社サービスを利用する場合、当社は、当該外部サービス提供者への個人データの提供を行うことがあります。<br /><br />
          <b>第7条(個人データの開示・共有)</b><br />
          当社は、個人データについては、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、お客様の承諾がない限り、第三者に開示又は共有することはありません。ただし、以下の場合は例外とします。<br />
          (1)当社が利用目的の達成に必要な範囲内において個人データの取扱いの全部又は一部を第三者に委託する場合<br />
          (2)お客様に利用料金を請求する目的で、決済システム会社、クレジット会社及び銀行に当社サービスを利用しているお客様の個人データを預託する場合<br />
          (3)合併その他の事由による事業の承継に伴って個人データが提供される場合<br />
          (4)国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合<br />
          (5)統計的情報を提供する目的で、個々の個人情報を集積又は分析し、個人を識別できない形に加工して、その統計データを開示する場合<br />
          (6)その他、個人情報保護法その他の法令で認められる場合<br />
          2 当社は、個人データを、第2条第15号ないし第19号に定める利用目的の範囲で、家族信託を取り扱う弁護士、税理士、司法書士その他の専門家等の当社と提携を行った第三者に対して提供することがあり、お客様はかかる第三者提供につき予め同意するものとします。　<br />
          第8条（個人データの共同利用）<br />
          当社は、上記第2条記載の利用目的の達成に必要な範囲内で、以下の通りお客様から提供された個人データを共同利用いたします。当該個人データの管理について責任を有する者の氏名若しくは名称、住所又は法人代表者の氏名に変更があった場合は遅滞なく、当該個人データの管理について責任を有する者を変更しようとする場合はあらかじめ、その旨についてお客様に通知し、又はお客様が知り得る状態に置きます。<br />
          (1)共同して利用される個人データの項目<br />
          当社サービスの利用を通じてお客様から提供された全ての個人情報<br />
          (2)共同して利用する者の範囲<br />
          株式会社グッドライフパートナーズ<br />
          司法書士法人クラフトライフ<br />
          (3)共同して利用する者の利用目的<br />
          ①信託契約書及び遺言書の文案作成、信託不動産に付帯又は関連して必要となる登記手続<br />
          ②当社サービスに付帯又は関連して発生する法律問題への対応<br />
          (4)当該個人データの管理について責任を有する者の名称及び住所並びに代表者氏名<br />
          東京都世田谷区用賀四丁目２８番２１号<br />
          株式会社グッドライフパートナーズ　<br />
          代表取締役　飯田真司<br /><br />
          <b>第9条(個人データの開示・訂正等)</b><br />
          お客様の個人データについて、お客様が書面により開示、利用目的の通知、訂正、追加、削除、利用の停止、消去、第三者への提供の停止（以下、「開示等」といいます。）を要望する場合は、申請書に本人確認(代理人による申請の場合、適正な代理人であることの確認)のために必要な書類を同封のうえ、郵送によりご請求ください。書面による開示請求及び利用目的の通知請求の場合には、１回の申請ごとに、手数料として５２０円(税込。レターパックにより書面にてご回答する実費)を同額分の郵便切手にて申し受けます。<br />
          2 前項に基づく申出があった場合において、本人確認がなされたときは、当社は、必要な調査を行い、その結果に基づき、合理的な範囲で個人データの開示等を行い、その旨（開示又は利用目的の通知の場合を除く。訂正、追加又は削除の場合はその内容を含む。）をお客様に通知いたします。ただし、個人情報保護法その他の法令により、当社が開示等の義務を負わない場合はこの限りではありません。お客様が開示を要望される個人データが存在しない場合、お客様が請求した方法による開示が困難である場合又は個人データの開示等を行わない旨の決定をした場合は、その旨お客様に通知いたします。<br /><br />
          <b>第10条(お問い合わせ)</b><br />
          当社の個人情報の取扱いに関するご意見、ご質問、苦情のお申出その他個人情報の取扱いに関する事項は、下記までお問い合わせください。<br />
          住所：〒１５８－００９７東京都世田谷区用賀４－２８－２１<br />
          株式会社グッドライフパートナーズ<br />
          代表取締役：飯田真司<br />
          個人情報取扱責任者：飯田真司<br />
          連絡先：０３－６８０５－７０３９又は当社ウェブサイトの「お問い合わせ」から<br /><br />
          <b>第11条(組織・体制)</b><br />
          当社は、代表取締役を個人情報管理責任者とし、個人情報の適正な管理及び継続的な改善を実施します。<br /><br />
          <b>第12条(免責)</b><br />
          当社は、以下の場合には、何らの責任を負いません。<br />
          (1) お客様ご本人が当社サービスの機能又は別の手段を用いて第三者に個人情報を明らかにした場合<br />
          (2) お客様が自ら当社サービス上に入力した情報等により、個人を識別し得る状態に至った場合<br /><br />
          <b>第13条(本ポリシーの変更)</b><br />
          当社は、個人データの取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本ポリシーをお客様の事前の了承を得ることなく変更することがあります。変更後の本ポリシーについては、当社が別途定める場合を除いて、当社ウェブサイトでの公表後、すぐに効力が発生するものとします。ただし、法令上お客様の同意が必要となるような内容の変更を行うときは、当社が定める方法により、お客様の同意を取得するものとします。<br /><br />
          <b>第14条(その他の注意事項)</b><br />
          当社が提供するサービスは、当社が管理するサービス以外のサービスへのリンクを含む場合があり、これら外部サービスにおける内容や個人情報の保護については、当社は一切責任を負いません。
        </Text>
      </Pop2>
    }
    {
      tokuteiIsOpen &&
      <Pop2 onClose={() => setTokuteiIsOpen(false)}>
        <Title>特定商取引法に基づく表記</Title>
        <Text>
          <b>【事業者の名称】</b><br />
          　株式会社グッドライフパートナーズ<br /><br />
          <b>【所在地】</b><br />
          　〒158-0097 東京都世田谷区用賀四丁目28番21号<br /><br />
          <b>【電話番号】</b><br />
          　０３－６８０５－７０３９<br /><br />
          <b>【代表者】</b><br />
          　代表取締役 飯田真司<br /><br />
          <b>【サービス内容】</b><br />
          　信託組成コンサルティング及び信託監督人事業「信託先生」の運営<br /><br />
          <b>【サービス料金・報酬等】</b><br />
          信託財産評価額が1億円未満​<br />
          　初期費用（家族信託組成サポートサービス）54,000円(税込59,400円)<br />
          　年額費用（信託監督人サービス）29,800円～(税込32,780円～)<br />
          信託財産評価額が1億円以上<br />
          　初期費用（家族信託組成サポートサービス）信託財産評価額の0.054%(税込0.0594%)<br />
          　年額費用（信託監督人サービス）29,800円～(税込32,780円～)<br /><br />
          <b>【サービス料金・報酬等以外の必要料金】</b><br />
          　契約書作成に係る専門家費用（約2万円～5万円）<br />
          　信託口口座開設に関する費用（約5万円）<br />
          　信託財産に不動産がある場合の登記費用（土地1筆・建物１戸（不動産評価額が5000万円）の場合は約26万円（司法書士報酬、登録免許税、実費含む））<br />
          　契約書の公正証書化を行う場合の費用（信託財産の価額が5000万円の場合は約5万円）<br />
          　担当者の出張費用（1万円～4万円）<br />
          　郵便費用等（約3,000円）<br />
          ※上記金額は標準額であり、事案や対応する専門家・金融機関によって変わる可能性がございます。<br /><br />
          <b>【支払い方法・支払時期】</b><br />
          　サービス利用の契約後、請求書を発行いたします。発行した請求書記載の期日までに指定する銀行口座に振込送金する方法によりお支払いいただきます。<br /><br />
          <b>【サービスの提供時期】</b><br />
          　サービス料金の支払後速やかに<br /><br />
          <b>【キャンセル】</b><br />
          　いつでも可能です。但し、信託監督人サービスについては信託契約の定めるところによります。すでに支払い済みの料金については、特段の合意のある場合を除き、返還いたしません。方法については担当者にお問い合わせください。<br /><br />
          <b>【特別の役務提供条件】</b><br />
          　お客様又はその関係者が暴力団員による不当な行為の防止等に関する法律（平成3年法律第77号。その後の改正を含む。）第2条で定義される暴力団、指定暴力団、指定暴力団連合、暴力団員若しくはこれらの関連者、総会屋等、社会運動等標ぼうゴロ、特殊知能暴力集団等若しくはこれらに準ずる者又はその構成員であると疑われる場合、又はお客様とその法定相続人の間に紛争が発生し又は紛争が発生するおそれがある場合は、サービスを提供できないことがあります。
        </Text>
      </Pop2>
    }
  </Outer>
}