import React, { FunctionComponent, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { inlineElementStyles } from '../css/inline-styles'

const Outer = styled.div`

  padding: min(1.5rem, calc(1.5 / 32 * 100vw));
  line-height: 1.5;
  font-size: min(1rem, calc(1 / 32 * 100vw));
  &:empty {
    display: none;
  }
  ${inlineElementStyles}
`

type ContentsProps = {

}
export const Contents: FunctionComponent<ContentsProps> = () => {
  const outerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const outer = outerRef.current
    if(outer){
      const contents = document.getElementById('top-contents')
      if(contents){
        const contentsParent = contents?.parentElement
        outer.appendChild(contents)
        return () => {
          contentsParent?.appendChild(contents)
        }
      }
      
    }
  }, [])
  return <Outer ref={outerRef}>
  </Outer>
}