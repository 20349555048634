import React, { FunctionComponent, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import SvgCalculateButton from '../generated/svg/calculate-button'
import SvgCompanyInfo from '../generated/svg/company-info'
import SvgContactButton from '../generated/svg/contact-button'
import SvgMain from '../generated/svg/main'
import SvgReturnToTopButton from '../generated/svg/return-to-top-button'
import { Contents } from './contents'
import { FloatButton } from './float-button'
import { Footer } from './footer'
import { Profiles } from './profiles'


const pop = keyframes`
  from {
    transform: scale(0) translateY(5rem);
  }
  to {
    transform: scale(1) translateY(0);
  }
`

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Outer = styled.div`
  background-color: #e8f4fa;
  .hide {
    opacity: 0;
  }
  .show {
    transform-origin: 50% 100%;
    animation: ${pop} 1s forwards cubic-bezier(.08,.82,.17,1), ${fade} 0.75s 0.25s forwards;
  }
`

const Inner = styled.div`
  background-color: #fff;
  margin: 0 auto;
  max-width: 32rem;
  position: relative;
  box-shadow: 0 0 0.5rem #0004;
`

const MainSvgContainer = styled.div`
  position: relative;
  margin-bottom: min(-2rem, calc(-2 / 32 * 100vw));
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`
const CompanyInfoSvgContainer = styled.div`
  position: relative;
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`

const FooterContainer = styled.div`

`

const FloatButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 50%;
  margin: 0 auto;
  max-width: 32rem;
  transform: translateX(50%);
  z-index: 200;
`

const ButtonContainer = styled.a`
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  &:hover {
    filter: saturate(1.5);
  }
  svg {
    display: block;
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
`

const ProfileContainer = styled.div`
  padding: 2rem 0;
  background-color: #e8f4fa;
  position: relative;
`

const ProfileTitle = styled.div`
  margin-left: 7%;
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: 140%;
`

const PhoneOverlay = styled.a`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  height: 0.6%;
  width: 40%;
`

const TopButtonContainer = styled.div`
  padding: 1rem 0;
  background-color: #e8f4fa;
  cursor: pointer;
  svg {
    display: block;
    width: 100%;
    height: auto;
    &:hover {
      opacity: 0.5;
    }
  }
`

type MainProps = {

}
export const Main: FunctionComponent<MainProps> = () => {
  useEffect(() => {
    const svg = document.querySelector('#main-svg')
    if(!svg) return
    const targets = svg?.querySelectorAll('g[data-name="pop"] > *')
    targets.forEach(target => {
      if(!(target instanceof SVGElement)) return
      const svgRect = svg.getBoundingClientRect()
      const targetRect = target.getBoundingClientRect()
      const x = (targetRect.left - svgRect.left + (targetRect.width / 2)) / svgRect.width
      const y = (targetRect.top - svgRect.top + targetRect.height) / svgRect.height
      target.style.transformOrigin = `${x * 100}% ${y * 100}%`
      target.classList.add('hide')
    })
    const listener = () => {
      targets.forEach(target => {
        const rect = target.getBoundingClientRect()
        if(rect.bottom - document.documentElement.clientHeight < 0) {
          target.classList.add('show')
        } 
      })
    }
    window.addEventListener('scroll', listener)
    window.addEventListener('resize', listener)
    listener()

  }, [])
  return <Outer>
    <Inner>
      <MainSvgContainer>
        <SvgMain id="main-svg"/>
        <ButtonContainer style={{top: '7.4%'}} href="/calc">
          <SvgCalculateButton />
        </ButtonContainer>
        <ButtonContainer style={{top: '94.9%'}} href="/calc">
          <SvgCalculateButton />
        </ButtonContainer>
        <ButtonContainer style={{top: '99.2%'}} href="/contact">
          <SvgContactButton />
        </ButtonContainer>
        <PhoneOverlay href="tel:0368057039" />
      </MainSvgContainer>
      <Contents />
      <CompanyInfoSvgContainer>
        <SvgCompanyInfo />
      </CompanyInfoSvgContainer>
      <ProfileContainer>
        <ProfileTitle>運営メンバー</ProfileTitle>
        <Profiles />
      </ProfileContainer>
      <TopButtonContainer onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'})}}>
        <SvgReturnToTopButton />
      </TopButtonContainer>
      <FooterContainer>
        <Footer />
      </FooterContainer>
      <FloatButtonContainer>
        <FloatButton />
      </FloatButtonContainer>
    </Inner>
  </Outer>
}