import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { profiles } from '../profile'
import { Image } from './image'
import { Pop } from './pop'

const Outer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: min(3rem, calc(3 / 32 * 100vw)) min(1rem, calc(1 / 32 * 100vw));
`

const Item = styled.div`
  width: 27.5%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`

const ImageContainer = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`

const Name = styled.div`
  text-align: center;
  text-decoration: underline;
  font-weight: 700;
  margin-top: min(1rem, calc(1 / 32 * 100vw));
  font-size: min(0.8rem, calc(0.8 / 32 * 100vw));
`

const Job = styled.div`
  margin-top: min(0.5rem, calc(0.5 / 32 * 100vw));
  text-align: center;
  font-weight: 700;
  line-height: 1.5;
  font-size: min(0.75rem, calc(0.75 / 32 * 100vw));
  white-space: pre-wrap;
`

const loopIncrement = (num: number, limit: number) => {
  return (num + 1) % limit
}
const loopDecrement = (num: number, limit: number) => {
  if(num < 1){
    return limit - 1
  } else {
    return (num - 1)
  }
}

type ProfilesProps = {

}
export const Profiles: FunctionComponent<ProfilesProps> = () => {
  const [pop, setPop] = useState<number | null>(null)
  return <Outer>
    {
      profiles.map((profile, i) => {
        return <Item key={i} onClick={() => setPop(i)}>
          <ImageContainer>
            <Image src={profile.image} />
          </ImageContainer>
          <Name>{profile.name}</Name>
          <Job>{profile.job}</Job>
        </Item>
      })
    }
    {
      typeof pop === 'number' &&
      <Pop
        profile={profiles[pop]}
        onNext={() => setPop(loopIncrement(pop, profiles.length))}
        onPrev={() => setPop(loopDecrement(pop, profiles.length))}
        onClose={() => setPop(null)}
      />
    }
  </Outer>
}