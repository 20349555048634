import * as React from "react";
import { SVGProps } from "react";

const SvgLeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30.454 21.316"
    {...props}
  >
    <g fill="none" stroke="#248ec7" strokeMiterlimit={10} strokeWidth={2.835}>
      <path d="m11.66 1.002-9.656 9.656 9.656 9.656M2.004 10.658h28.45" />
    </g>
  </svg>
);

export default SvgLeftArrow;
