import * as React from "react";
import { SVGProps } from "react";

const SvgCloseButton = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26.154 26.154"
    {...props}
  >
    <g fill="none" stroke="#000" strokeMiterlimit={10} strokeWidth={2.835}>
      <path d="M25.151 1.002 1.002 25.151M1.002 1.002l24.149 24.149" />
    </g>
  </svg>
);

export default SvgCloseButton;
