import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgCloseButton from '../generated/svg/close-button'
import { Profile } from '../profile'

const Outer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const Background = styled.div`
  background-color: #0008;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  cursor: pointer;
`

const Window = styled.div`
  z-index: 1;
  width: 100%;
  align-self: stretch;
  max-width: min(30rem, calc(30 / 32 * 100vw));
  background-color: #fff;
  border-radius: min(3rem, calc(3 / 32 * 100vw));
  padding: min(3rem, calc(3 / 32 * 100vw));
  margin-top: min(1rem, calc(1 / 32 * 100vw));
  margin-bottom: min(9rem, calc(9 / 32 * 100vw));
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
`

const CloseButton = styled.div`
  position: absolute;
  right: min(2rem, calc(2 / 32 * 100vw));
  top: min(2rem, calc(2 / 32 * 100vw));
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
  svg {
    display: block;
    height: min(2rem, calc(2 / 32 * 100vw));
    width: auto;
  }
`

type Pop2Props = {
  onClose: () => void
}
export const Pop2: FunctionComponent<Pop2Props> = ({onClose, children}) => {
  return <Outer>
    <Background onClick={() => onClose()}/>
    <Window>
      {children}
      <CloseButton onClick={() => onClose()}>
        <SvgCloseButton />
      </CloseButton>
    </Window>
  </Outer>
}