export type Profile = {
  name: string;
  image: string;
  job: string;
  text: string;
}
export const profiles: Profile[] = [
  {
    name: '飯田 真司',
    image: '/images/p01.png',
    job: '代表取締役社長\n司法書士',
    text: '司法書士として多くの家族信託に携わるも、顧客が資産家に偏り、一般家庭に広がらない信託サービスに疑念を抱いたことから、“誰でも利用できる医療保険”をイメージした信託サービスを構築し、本事業を創業。本事業、司法書士事業の他に、一般社団法人終活の窓口を創業し、漫才を通して終活について学べる終活漫才を提供するなど、高齢化社会における課題解決に多角的に取り組む。自身が漫才を行うこともある。'
  },
  {
    name: '佐賀 浩二',
    image: '/images/p02.png',
    job: '信託先生コンサルタント\n司法書士',
    text: '専修大学大学院法学研究科修士課程修了。都内の司法書士法人で１０年以上勤務し、様々な業務経験を積む。認知症の祖母の介護状況を成年後見人として目の当たりにした経験と、これまでの司法書士としての経験から様々な制度を比較・提案し、ご家族の皆様が不安のない未来を実現するため、信託先生コンサルタントとして邁進。'
  },
  {
    name: '粕谷 幸男',
    image: '/images/p03.png',
    job: '取締役\n税理士',
    text: '世田谷区用賀に事務所を持つ、KASUYA税理士法人代表社員。数多くの経営者をサポートする、税法・税務のプロフェッショナルとして、45年以上の経験と年間500件以上の豊富な実績を持つ。横浜商科大学の非常勤講師として、信託会計、信託税務を教授。'
  },
  {
    name: '新井 佑',
    image: '/images/p05.png',
    job: '取締役',
    text: '世田谷区用賀生まれ。株式会社リクルート、株式会社アサツーディ・ケイ(ADK)を経て独立・起業。株式会社mokumoku代表取締役社長、NPO法人neomura代表理事、一般社団法人終活の窓口理事など。営利・非営利の豊富な経験と多様なネットワークを軸に、社会課題をクリエイティブのチカラで解決し、より豊かな社会の実現を目指す。'
  },
  {
    name: '杉野 由和',
    image: '/images/p06.png',
    job: '顧問弁護士\n兼コンサルタント',
    text: '「長島・大野・常松法律事務所のパートナー・東京大学法科大学院客員教授等を経てエール法律事務所を開設し企業法務及び民事全般に対応。「アドバンス会社法」「合併ハンドブック」「会社分割ハンドブック」（共著：商事法務）、「International Labour and Employment Compliance Handbook Japan」（Kluwer Law International）その他執筆・講演多数。」法律事務所のほか、経済界で活躍してきたメンバーを中心とするエール・ビジネスコンサルティング株式会社を通じ、企業社会で蓄積された各分野の知見を社会に還元している。<a href="https://yellbizlaw.com/">エール・ビジネスコンサルティング株式会社</a>'
  },
]