import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgCloseButton from '../generated/svg/close-button'
import SvgLeftArrow from '../generated/svg/left-arrow'
import SvgRightArrow from '../generated/svg/right-arrow'
import { Profile } from '../profile'
import { Image } from './image'

const Outer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const Background = styled.div`
  background-color: #0008;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  cursor: pointer;
`

const Window = styled.div`
  z-index: 1;
  width: 100%;
  align-self: stretch;
  max-width: min(30rem, calc(30 / 32 * 100vw));
  background-color: #fff;
  border-radius: min(3rem, calc(3 / 32 * 100vw));
  padding: min(3rem, calc(3 / 32 * 100vw));
  margin-top: min(1rem, calc(1 / 32 * 100vw));
  margin-bottom: min(9rem, calc(9 / 32 * 100vw));
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
`

const ImageContainer = styled.div`
  width: 40%;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Row = styled.div`
  display: flex;
  gap: min(2rem, calc(2 / 32 * 100vw));
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Name = styled.div`
  font-weight: 700;
  font-size: min(1.5rem, calc(1.5 / 32 * 100vw));
`

const Job = styled.div`
  font-size: min(0.9rem, calc(0.9 / 32 * 100vw));
  white-space: pre-wrap;
  line-height: 1.5;
  font-weight: 700;
  margin-top: min(1rem, calc(1 / 32 * 100vw));
`

const Text = styled.div`
  font-size: min(1.2rem, calc(1.2 / 32 * 100vw));
  font-weight: 700;
  line-height: 2;
  margin-top: min(2rem, calc(2 / 32 * 100vw));
  flex: 1 1 0;
  overflow-y: scroll;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: min(1rem, calc(1 / 32 * 100vw));
`

const Button = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
  svg {
    display: block;
    height: min(2rem, calc(2 / 32 * 100vw));
  }
`

const CloseButton = styled.div`
  position: absolute;
  right: min(2rem, calc(2 / 32 * 100vw));
  top: min(2rem, calc(2 / 32 * 100vw));
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
  svg {
    display: block;
    height: min(2rem, calc(2 / 32 * 100vw));
    width: auto;
  }
`

type PopProps = {
  profile: Profile
  onNext: () => void
  onPrev: () => void
  onClose: () => void
}
export const Pop: FunctionComponent<PopProps> = ({profile, onNext, onPrev, onClose}) => {
  return <Outer>
    <Background onClick={() => onClose()}/>
    <Window>
      <Row>
        <ImageContainer>
          <Image src={profile.image} />
        </ImageContainer>
        <Info>
          <Name>{profile.name}</Name>
          <Job>{profile.job}</Job>
        </Info>
      </Row>
      <Text dangerouslySetInnerHTML={{__html: profile.text}}/>
      <ButtonContainer>
        <Button onClick={() => onPrev()}>
          <SvgLeftArrow />
        </Button>
        <Button onClick={() => onNext()}>
          <SvgRightArrow />
        </Button>
      </ButtonContainer>
      <CloseButton onClick={() => onClose()}>
        <SvgCloseButton />
      </CloseButton>
    </Window>
  </Outer>
}