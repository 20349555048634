import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgPhoneButton from '../generated/svg/phone-button'
import SvgWebContactButton from '../generated/svg/web-contact-button'

const Outer = styled.div`
  padding: 1rem;
  display: flex;
  gap: 1rem;
  position: relative;
`

const Background = styled.div`
  background-color: #fff8;
  backdrop-filter: blur(0.5rem);
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`

const ButtonContainer = styled.a`
  display: block;
  &:hover {
    filter: saturate(1.5);
  }
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`

type FloatButtonProps = {

}
export const FloatButton: FunctionComponent<FloatButtonProps> = () => {
  return <Outer>
    <Background />
    <ButtonContainer href="tel:0368057039">
      <SvgPhoneButton />
    </ButtonContainer>
    <ButtonContainer href="/contact">{/* FIXME */}
      <SvgWebContactButton />
    </ButtonContainer>
  </Outer>
}