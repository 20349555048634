import * as React from "react";
import { SVGProps } from "react";

const SvgRightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30.454 21.316"
    {...props}
  >
    <g fill="none" stroke="#248ec7" strokeMiterlimit={10} strokeWidth={2.835}>
      <path d="m18.794 1.002 9.656 9.656-9.656 9.656M28.45 10.658H0" />
    </g>
  </svg>
);

export default SvgRightArrow;
