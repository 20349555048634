import { createGlobalStyle } from 'styled-components';

export const RootCss = createGlobalStyle`
  :root {
    font-family: sans-serif;
  }
  a {
    color: inherit;
    &:hover {
      text-decoration: inherit;
    }
  }
  
`